.upperdown {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.upperdown h2 {
    margin-top: 2rem;
}

.upperdown .img {
    margin-bottom: 10px;
    width: 300px;
}

.upperdown img {
    max-width: 100%;
}

.upperdown-context p {
    color: rgb(132, 129, 129);
}

.upperdown-context span {
    color: #1c2f4c;
    font-weight: 500;
    font-size: 1.5rem;
}

@media (min-width:768px) {
    .upperdown {
        padding: 0 20% 0 20%;
    }

    .upperdown .img {
        margin-bottom: 20px;
    }
}