/* main-section */
.main-section {
    margin: 0;
    background-image: url(../../public//img/pattern-white.png);
    background-repeat: repeat;
    background-position: 0 0 !important;
}

.section {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media (min-width:768px) {
    .section {
        width: 750px;
    }
}

@media (min-width:992px) {
    .section {
        width: 970px;
    }
}

@media (min-width:1200px) {
    .section {
        width: 1180px;
    }
}

/* font */
a,
body,
code,
del,
div,
em,
h1,
h2,
h3,
h4,
h5,
h6,
html,
p,
span,
table,
tbody,
td,
th,
tr,
tt,
ul,
var,
li {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.5;
    border: 0,
}

h1,
h2,
h3,
h4 {
    font-family: "Raleway", sans-serif;
    padding: 0;
    color: #1c2f4c;
}

h1,
h2 {
    text-align: center;
}

h1,
h2,
h3 {
    font-weight: 600;
}

h4,
h5,
h6 {
    font-weight: 400;
}

p,
span,
table,
tbody,
td,
th,
tr,
tt,
ul,
li,
a {
    font-size: large;
}

/* grid */


/* list */
li {
    list-style-type: none;
    padding: 0px 4px;
        margin-bottom: 0.5rem;
    }
    
    .check-mark-list-item {
        color: lightslategrey;
        margin-right: 0.3rem;
    }
    
    .custom-bullet-list li::before {
        content: "\273F";
        font-weight: bold;
        font-size: x-large;
        color: #1c2f4c;
        margin-right: 0.5rem;
    }

.bullet-point-icon {
    margin-right: 5px;
}

/* table */
table {
    border-collapse: separate;
    width: 100%;
    border-spacing: 5px;
    border: none;
}
table th,
td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
}
table th {
    background-color: #DCDCDC;
    color: #B8860B;
    font-weight: bold;
    font-size: larger;
}
@media (max-width: 600px) {
    .table {
        border: 0;
        border-bottom-width: 0;
    }

        .table thead {
            display: none;
    }

        .table tr {
            display: block;
    }
        .table td {

        display: block;
    }

        .table td {
            position: relative;
            display: block;
            text-align: right;
            font-size: 0.5em;
    }

        .table td::before {
            position: absolute;
            left: 2px;
            content: attr(data-label);
        }
    
}
        /* table list */
        @media (max-width: 600px) {
            .list-table td {
                display: block;
            }
    
                .list-table td+td {
                    margin-top: 20px;
                    /* Add spacing between the two columns */
                }
          }
    .table-body-list li {
        margin-right: 5px;
    }

    .table-body-list td {
        text-align: start;
    }

    /* image */
    img {
        vertical-align: middle;
        border: 0;
        overflow-clip-margin: content-box;
        overflow: clip;
        max-width: 100%;
    }

    /* link */
    a {
        text-decoration: none;
        color: rgb(184, 134, 11);
            font-weight: 600;
            font-style: italic;
    }
.line {
    width: 50%;
    border: 2px solid rgb(239, 172, 72);
}
.title h2 {
    color: white;
}
.note {
    color: #B8860B;
    font-style: italic;
    font-weight: bold;

}