.two-col-grid-bg {
    display: grid;
    grid-template-rows: auto auto;
    /* Two rows with auto height */
    grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    /* Three columns with equal width */
    /* Gap between grid items */
    justify-content: center;
    /* Center items horizontally */
    align-items: center;
    /* Center items vertically */
}

.grid-img-bg {
    display: grid;
    place-items: center;
    /* 水平和垂直居中 */
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    height: 100%;
}

.grid-img-bg img {
    object-fit: contain;
    max-width: 1000px;
    width: 100%;
    height: auto;
}

.grid-text-bg {
    padding: 0 10% 0 10%;
}




@media (min-width:1024px) {
    .two-col-grid-bg {
        grid-template-columns: repeat(auto-fill, minmax(700px, 1fr));
    }

    .text-reversed {
        grid-row: 1;
        grid-column: 1;
    }

    .img-reversed {
        grid-row: 1;
        grid-column: 2;
    }
}